import React from 'react';
import moment from 'moment';

import { richText } from '../../util/richText';
import { injectIntl } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { priceData } from '../ListingCard/ListingCard';

import { NamedLink, ResponsiveImage } from '..';

import css from './TalentCard.module.css';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const TalentCard = props => {
    const {
        intl,
        listing,
        className,
        rootClassName,
        listingConfig,
    } = props;

    const { id, attributes, author } = listing;
    const { title, price, publicData, } = attributes || {};
    const {uuid} = id || {};

    const {
        skills,
        location,
        addSelfie1,
        experiences,
        uploadVideoInPhotoes,
        roles,
        listingType
    } = publicData || {};

    const { displayName, publicData: APublicData } = (author && author.id && author.attributes.profile) || {};
    let minRateValue = Infinity;
    let maxRateValue = -Infinity;
  
    if (roles && roles.length && listingType === 'company') {
      roles.forEach(role => {
        if (role.perHourRate && role.perHourRate.label) {
          let rateLabel = role.perHourRate.label;
          let rates = rateLabel.match(/\d+/g).map(Number); // Extract numerical values
  
          rates.forEach(rate => {
            if (rate < minRateValue) {
              minRateValue = rate;
            }
            if (rate > maxRateValue) {
              maxRateValue = rate;
            }
          });
        }
      });
    }
    let minRateLabel = `CA$${minRateValue}`;
  let maxRateLabel = `$${maxRateValue}`;
    const {
        mainProfession,
        availableHoursPerWeek,
        companyImage=''
    } = (APublicData) || {};
    const classes = classNames(rootClassName || css.cardroot, className);
    function getInitials(name) {
        return name.split(' ').map(word => word.charAt(0).toUpperCase()).join('');
    }
    const initials = getInitials(title);
    const slug = createSlug(initials);
    const { formattedPrice, priceTitle } = priceData(price, price.currency, intl);
    const yearOfExperience = (experiences && experiences.length && experiences[0].inputValues && experiences[0].inputValues.yearOfExperience && experiences[0].inputValues.yearOfExperience.key)
        ? moment().diff(moment(parseInt(experiences[0].inputValues.yearOfExperience.key), 'YYYY'), 'year')
        : null;

    return (
        <NamedLink
            name="AlgoliaSearchPage"
            className={classes}
            to={{
                search: uuid
                    ? '&query=' + uuid
                    : '',
            }}
        >
            <div
                className={css.root}
            >
                <div className={css.borderRadius}>
                    <div className={css.cardContent}>
                        <div className={css.cardImg}>
                            <LazyImage
                                rootClassName={css.cardImg}
                                alt={title}
                                url={companyImage? companyImage :  uploadVideoInPhotoes ? (uploadVideoInPhotoes.replace(/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i, '.png')) : addSelfie1}
                            // sizes={renderSizes}
                            />
                        </div>
                        {/* <div className={css.cardImg}>
                            <img src={addSelfie1} alt='profile' />
                        </div> */}
                        <div className={css.cardRightContent}>
                            {initials && listingType !== 'company' ? (
                                <h4>{initials}</h4>
                            ) : (
                                <h4>{title}</h4>
                            )}
                            {/* {displayName
                                ? <h4>{displayName.split(' ')[0]}</h4>
                                : null} */}
                            {experiences && experiences.length
                                ? <h5>
                                    {experiences[0].label}
                                </h5>
                                : null}
                            <ul>
                                {yearOfExperience
                                    ? <li>{yearOfExperience}{yearOfExperience > 1 ? ' years' : ' year'} experience </li>
                                    : null}
                                {availableHoursPerWeek ? <li>{availableHoursPerWeek}h per week available</li> : null}
                                <li>
                                    {location && location.timeZone
                                        ? moment().tz(location.timeZone).format('z')
                                        : moment().tz(moment.tz.guess()).format('z')} Timezone
                                </li>
                                {(roles && roles.length && roles[0] && roles[0].perHourRate && roles[0].perHourRate.label)
                                    ? <li>
                                        {roles[0].availableHoursPerWeek.label} Weekly
                                    </li>
                                    : null}
                                {roles && roles.length ? <li>Start {roles[0].start}</li> : null}
                            </ul>
                          
                        </div>
                    </div>
                    {/* {skills && skills.length
                        ? <div className={css.skills}>
                            {skills.map(({ key, label }) => (
                                <span key={key}>
                                    {label}
                                </span>))}
                        </div>
                        : null} */}

                </div>


                {/* <p className={css.price}>{(listingType && listingType == 'company') ? `${minRateLabel}-${maxRateLabel} per hour` : formattedPrice}</p> */}
                <p className={css.cardType}>
                    {listingConfig && listingConfig.companyIndustry
                        ? listingConfig.companyIndustry.find(ind => ind.option === mainProfession)?.label
                        : null}
                </p>
            </div>
        </NamedLink>
    );
};

export default injectIntl(TalentCard);
