import React, { useState } from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
// import { nonEmptyArray } from '../../util/validators';
import { IconInfo, OutsideClickHandler, ValidationError } from '../../components';

import css from './FieldMultiSelect.module.css';

export const customSelectStyles = {
  menuList: styles => ({
    ...styles,
    background: '#eee',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? 'hsla(291, 64%, 42%, 0.5)'
      : isSelected
        ? 'hsla(291, 64%, 42%, 1)'
        : undefined,
    zIndex: 1,
  }),
  menu: base => ({
    ...base,
    zIndex: 100,
    background: "#FFB169"
  }),
  control: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    backgroundColor: '#fff',
    minHeight: '45px',
    boxShadow: "none",
    borderColor: "#939393 !important",
    cursor: "pointer"
  }),
  valueContainer: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? '#303030' : '#B2B2B2',
    height: '100%',
    paddingLeft: '10px',
    margin: '0px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.05em',
    color: '#212121',
    fontSize: '16px',
    fontFamily: 'Inter',
  }),
  placeholder: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: '#595858',
    // paddingLeft: '15px',
    margin: '0px',
    fontWeight: '400',
    fontFamily: 'Inter',
  }),
  indicatorsContainer: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    padding: '0px',
    margin: '0px',
    color: isSelected ? "#000" : "#000",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '0px',
    margin: '0px',
    transition: 'all .2s ease',
    transform: state.isFocused.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  input: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? '#303030' : '#B2B2B2',
    height: '100%',
    padding: '0px 0',
    margin: '0px',
    fontWeight: '500',
    fontFamily: 'Inter',
  }),
  indicatorSeparator: styles => ({ display: 'none', padding: '0px', margin: '0px' }),
  option: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: '#303030',
    padding: '15px',
    fontSize: '15px',
    lineHeight: '100%',
    letterSpacing: '-0.04em',
    cursor: 'pointer !important',
    borderLeft: '1px solid #eee',
    borderRight: '1px solid #eee',
    fontFamily: 'Inter',
    // boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    backgroundColor: isDisabled ? null : isFocused ? '#FFB169' : isSelected ? '#FFF' : null,
  }),
  singleValue: (styles, { isFocused, isDisabled, isSelected }) => ({
    ...styles,
    color: isSelected ? '#303030' : '#303030',
    fontWeight: '400',
    marginBottom: '4px',
    fontFamily: 'Inter',
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#b0b0b5',
    }
  },
  menu: base => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    backgroundColor: '#fff',
    color: '#303030',
    position: 'absolute',
    top: '100%',
    left: '0',
    right: '0',
    width: '100%',
    minWidth: '150px',
    padding: '0px',
    fontFamily: 'Inter',
  }),
  // menuList: base => ({
  //   ...base,
  //   padding: '0px',
  //   backgroundColor: '#fff',
  //   fontSize: '15px',
  //   lineHeight: '100%',
  //   letterSpacing: '-0.04em',
  //   color: '#303030',
  // }),
  dropdownIndicator: base => ({
    ...base,
    color: '#303030',
    fontFamily: 'Inter',
  }),
};
const FieldMultiSelect = props => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [currFocus, setCurrFocus] = useState(false);
  const [isSelected, setSelected] = useState(false);

  // REACT-SELECT DOESNT PLAY WELL WITH SSR
  // IF NO WINDOW DONT RENDER
  if (typeof window === 'undefined') {
    return null;
  }
  // CONDITIONALLY IMPORT SELECT MODULES
  const { default: Select, components } = require('react-select'); // eslint-disable-line global-require
  const {
    validate,
    name,
    options,
    label,
    className,
    placeholder,
    meta,
    isMulti,
    isClearable,
    isRequired,
    disabled,
    defaultValue,
    customErrorText,
    onSelection,
    showIButton = true,
    selectionLimit,
    ...rest
  } = props;

  const status =
    classNames(css.inputWrapper, isSelected && isRequired && !selectedOptions
      ? css.error
      : selectedOptions && selectedOptions.value !== ''
        ? css.success
        : css.attention);

  const Option = props => (
    <div>
      <components.Option {...props}>
        <label className={css.multiselectValueLabel}>{props.label}</label>
      </components.Option>
    </div>
  );

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span className={css.multiSelectLabel}>{props.data.label}</span>
    </components.MultiValue>
  );
  const renderSelect = typeof window !== 'undefined';

  return options && renderSelect ? (
    <Field name={name} validate={validate}>
      {props => {
        const { input, meta } = props;
        // PULLING INPUT ONCHANGE OUT OF OBJECT SO THAT WE CAN IMPLEMENT OUT OWN
        const { onChange, ...rest } = input;
        const { invalid, touched, error } = meta;
        const errorText = customErrorText || error;

        // Error message and input error styles are only shown if the
        // field has been touched and the validation has failed.
        const hasError = !!customErrorText || !!(touched && invalid && error);

        const fieldMeta = { touched: hasError, error: errorText };

        return (
          <OutsideClickHandler className={css.inputForm}  onOutsideClick={() => {
            setCurrFocus(false);
           
          }}>
            <div className={className}>
              {label
                ? <label className={css.gap}>
                  <span className={css.iconHover}>{label}
                    {selectionLimit ?
                      <span className={css.infoIcon}>
                        <IconInfo />
                        <span className={currFocus ? css.currTooltip : css.tooltiptext}>Choose up to {selectionLimit} of your top skills</span>
                      </span>
                      :
                      isMulti
                        ? <span data-tooltip="Choose one or more and enter your own">
                          &nbsp;<IconInfo />
                        </span>
                        : null}
                  </span>
                </label>
                : null}
              <div className={status}>
                <Select
                  closeMenuOnSelect={!isMulti}
                  hideSelectedOptions={false}
                  isMulti={isMulti}
                  components={[Option, MultiValue]}
                  defaultValue={
                    defaultValue && Object.keys(defaultValue).length ? defaultValue : undefined
                  }
                  options={options}
                  isClearable={isClearable}
                  isSearchable={isMulti}
                  styles={customSelectStyles}
                  isDisabled={disabled}
                  placeholder={placeholder}
                  selected={selectedOptions}
                  getOptionValue={value => {
                    setSelected(true);
                    return value.value || value.lable;
                  }}
                  {...rest}
                  onBlur={() => {
                    input.onBlur(input.value);
                    typeof onSelection == 'function' && onSelection(selectedOptions);
                  }}
                  onChange={item => {
                    console.log(item, 'itemitem');
                    if (selectionLimit && item.length > selectionLimit) {

                    } else {
                      setSelectedOptions(item)
                      input.onChange(item);
                    }
                  }}
                  className={css.dropDownWrapper}
                  onFocus={() => {
                    setCurrFocus(true);
                  }}
                />

                <ValidationError fieldMeta={fieldMeta} />
              </div>
            </div>
          </OutsideClickHandler>
        );
      }}
    </Field>
  ) : null;
};

export default FieldMultiSelect;
