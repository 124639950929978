import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { LISTING_STATE_DRAFT, propTypes } from '../../../util/types';
import { createSlug } from '../../../util/urlHelpers';

import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ExternalLink,
  IconShare,
} from '../../../components';
import requestformLogo from '../../../assets/images/requestform-logo.png';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserListings,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);
  const currentUserHasPublishedListing = currentUserListings && currentUserListings.length
    ? currentUserListings[0].attributes.state !== LISTING_STATE_DRAFT
    : false;

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const { accountType } = (currentUser && currentUser.id && currentUser.attributes && currentUser.attributes.profile.publicData) || {};
  const { currentSeeds = 0, CurrentTokens } = (currentUser && currentUser.id && currentUser.attributes && currentUser.attributes.profile.protectedData) || {};
  const CurrentSeeds = currentSeeds || CurrentTokens;
  const isCompany = accountType ? accountType == 'company' : true;
  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={classNames(css.inboxLink, isCompany && CurrentSeeds ? css.coinWrapper : '')}
      name="InboxPage"
      params={{ tab: !isCompany ? 'sales' : 'orders' }}
    >
      <span>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
      {/* {isCompany && CurrentSeeds
        ? <span className={classNames(css.coins, CurrentSeeds > 9 ? css.Largecoins : null)}>
          {CurrentSeeds}
        </span>
        : null} */}
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const menuItems = [];
  if (isCompany) {
    menuItems.push({
      key: "ManageListingsPage",
      className: classNames(css.yourListingsLink, currentPageClass('ManageListingsPage')),
      name: "ManageListingsPage",
      id: "TopbarDesktop.yourListingsLink"
    });
  }

  if (!isCompany) {
    if (currentUserListings && currentUserListings.length) {
      menuItems.push({
        key: "EditListingPage",
        className: classNames(css.yourListingsLink, currentPageClass('ManageListingsPage')),
        name: "EditListingPage",
        id: "Edit Profile",
        params: {
          id: currentUserListings[0].id.uuid,
          slug: createSlug(currentUserListings[0].attributes.title),
          type: currentUserHasPublishedListing ? 'edit' : 'draft',
          tab: 'details'
        }
      });
    } else {
      menuItems.push({
        key: "NewListingPage",
        className: classNames(css.yourListingsLink, currentPageClass('ManageListingsPage')),
        name: "NewListingPage",
        id: "Add Profile"
      });
    }


  }
  menuItems.push(...[{
    key: "Step1Page",
    className: classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage')),
    name: "Step1Page",
    id: "TopbarDesktop.profileSettingsLink"
  },
  {
    key: "AccountSettingsPage",
    className: classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage')),
    name: "AccountSettingsPage",
    id: "TopbarDesktop.accountSettingsLink"
  },
  {
    key: "logout",
    rootClassName: css.logoutButton,
    className: css.menuItemBorder,
    onClick: onLogout,
    id: "TopbarDesktop.logout"
  }]);

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {menuItems.map(({ id, name, key, rootClassName, className, onClick, params }) => <MenuItem key={key} >
          {onClick
            ? <InlineTextButton rootClassName={rootClassName} onClick={onClick}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id={id} />
            </InlineTextButton>
            : <NamedLink
              className={className}
              name={name}
              params={params ? params : null}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id={id} />
            </NamedLink>}
        </MenuItem>)}
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  // const loginLink = isAuthenticatedOrJustHydrated ? null : (
  //   <NamedLink name="LoginPage" className={css.loginLink}>
  //     <span className={css.login}>
  //       <FormattedMessage id="TopbarDesktop.signIn" />
  //     </span>
  //   </NamedLink>
  // );


  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <div className={css.loginMenu}>
      <Menu className={css.loginmenuWrapper}>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <svg width="41" height="27" viewBox="0 0 41 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.833374 1.20834H8.20837M40.1667 1.20834H18.0417M40.1667 25.7917H32.7917M0.833374 25.7917H22.9584M0.833374 13.5H40.1667" stroke="white" stroke-width="1.5" stroke-linecap="round" />
          </svg>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent} key="login-menu">
          {/* <LinkedLogo
            className={css.logoLink}
            format="desktop"
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
          /> */}
          <MenuItem key="logo" className={css.menuClose}>
            <LinkedLogo
              className={css.logoLink}
              format="desktop"
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
            />
            <span className={css.close}>
              <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.846 0.346C10.026 0.346 10.182 0.412 10.314 0.543999C10.458 0.675999 10.53 0.825999 10.53 0.993999C10.53 1.15 10.476 1.3 10.368 1.444L6.408 6.79L5.598 5.656L9.27 0.688C9.426 0.46 9.618 0.346 9.846 0.346ZM1.422 0.346C1.674 0.346 1.872 0.448 2.016 0.651999L10.368 11.902C10.464 12.046 10.512 12.196 10.512 12.352C10.512 12.568 10.434 12.742 10.278 12.874C10.122 13.006 9.96 13.072 9.792 13.072C9.552 13.072 9.354 12.97 9.198 12.766L0.846 1.498C0.75 1.378 0.702 1.234 0.702 1.066C0.702 0.862 0.774 0.694 0.918 0.561999C1.074 0.418 1.242 0.346 1.422 0.346ZM1.35 13.072C1.158 13.072 0.996 13.006 0.864 12.874C0.744 12.73 0.684 12.586 0.684 12.442C0.684 12.274 0.744 12.112 0.864 11.956L4.842 6.538L5.616 7.726L1.926 12.748C1.782 12.964 1.59 13.072 1.35 13.072Z" fill="white" />
              </svg>

            </span>
          </MenuItem>
          <MenuItem key="login-btn">
            <NamedLink name="LoginPage" className={css.loginLink}>
              <span className={css.login}>
                <FormattedMessage id="TopbarDesktop.signIn" />
              </span>
            </NamedLink>
            <div className={css.menuLinks2}>
              <NamedLink className={css.navLink} name="CMSPage" params={{ pageId: 'about' }}>
                <FormattedMessage id="TopbarDesktop.about" />
              </NamedLink>
              <NamedLink className={css.navLink} name="CMSPage" params={{ pageId: 'for-companies' }}>
                <FormattedMessage id="Forteh For Companies" />
              </NamedLink>
              {/* <NamedLink className={css.navLink} name="LandingPage">
              <FormattedMessage id="TopbarDesktop.communityPage" />
            </NamedLink> */}
              <NamedLink className={css.navLink} name="CMSPage" params={{ pageId: 'fractional-pros' }}>
                <FormattedMessage id="Forteh For Fractional Pros" />
              </NamedLink>
              <NamedLink className={css.navLink} name="CMSPage" params={{ pageId: 'your-forte' }}>
                <FormattedMessage id="General FAQs" />
              </NamedLink>
              <NamedLink className={css.navLink} name="CMSPage" params={{ pageId: 'how-it-works' }}>
                <FormattedMessage id="How Forteh Works" />
              </NamedLink>
            </div>
            <div className={css.socialMediaLinks}>
              {/* <div className={css.socialMediaIcons} id="facebook"><ExternalLink href="https://www.facebook.com/fortehbymetier/" target="_blank">
                <IconShare type='facebook' />
              </ExternalLink></div> */}
              <div className={css.socialMediaIcons} id="linkedin"><ExternalLink href="https://www.linkedin.com/company/fortehbymetier/" target="_blank">
                <IconShare type='linkedin' />
              </ExternalLink></div>
              <div className={css.socialMediaIcons} id="instagram"><ExternalLink href="https://www.instagram.com/fortehbymetier/" target="_blank">
                <IconShare type='instagram' />
              </ExternalLink></div>
            </div>
          </MenuItem>
        </MenuContent>
      </Menu>


    </div>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      
      {currentPage == "AlgoliaSearchPage" && search}
      <div className={css.rightBoxHeader}>
        {isAuthenticated ?  <span className={css.menuLinks}>
          <NamedLink className={css.navLink} name="CMSPage" params={{ pageId: 'about' }}>
            <FormattedMessage id="TopbarDesktop.about" />
          </NamedLink>

          {/* <NamedLink className={css.navLink} name="CMSPage" params={{ pageId: 'solutions' }}>
            <FormattedMessage id="TopbarDesktop.solutions" />
          </NamedLink> */}
          {/* <NamedLink className={css.navLink} name="LandingPage">
            <FormattedMessage id="TopbarDesktop.communityPage" />
          </NamedLink> */}
          <NamedLink className={css.navLink} name="CMSPage" params={{ pageId: 'how-it-works' }}>
            <FormattedMessage id="TopbarDesktop.resources" />
          </NamedLink>
          {isAuthenticated
            ? accountType == "fractionalPro"
              ? null
              : <NamedLink className={classNames(css.navLink, css.coinWrapper)} name="PurchaseAcornsPage">
                <span className={css.logoWrapper}><img src={requestformLogo} /></span>
                <span className={classNames(css.coins, CurrentSeeds > 9 ? css.Largecoins : null)}>{CurrentSeeds}</span>
              </NamedLink>
            : null}
        </span> : null}
       

        {(isCompany && currentUserListings && currentUserListings.length) ? <NamedLink name="AlgoliaSearchPage" className={css.createListingLink}>
          <span className={css.createListing}>
            Discover Talent
          </span>
        </NamedLink> : null}
       {isAuthenticated ? isCompany
          ? <NamedLink className={css.createListingLink} name={"NewCompanyListingPage"}>
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createCompanyListing" />
            </span>
          </NamedLink>
          : currentUserListings && currentUserListings.length
            ? <NamedLink
              name={currentUserHasPublishedListing ? "ListingPage" : "EditListingPage"}
              className={css.createListingLink}
              params={{
                id: currentUserListings[0].id.uuid,
                slug: createSlug(currentUserListings[0].attributes.title),
                type: currentUserHasPublishedListing ? 'edit' : 'draft',
                tab: 'what-say'
              }}
            >
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.editListing" />
              </span>
            </NamedLink>
            : <NamedLink className={css.createListingLink} name={"NewListingPage"}>
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </NamedLink> : <>
            <NamedLink className={css.createListingLink} name={"SignupPageType"} params={{type: 'company'}}>
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.forCompanies" />
              </span>
            </NamedLink>
            <NamedLink className={css.createListingLink} name={"SignupPageType"} params={{type: 'pro'}}>
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.forPro" />
              </span>
            </NamedLink>
            
            </>} 


        {inboxLink}
        {profileMenu}
        {/* {signupLink} */}
        {loginLink}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
